import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import mdToHtml from '../util/mdToHtml';

import Layout from '../components/Layout';
import CtaBar from '../components/CtaBar';
import BlogFeatures from '../components/BlogFeatures';
import Button from '../components/Button';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import { PageWrap } from '../styles/common';

export const TeamDetailTemplate = ({
	vcard,
	bio,
	name,
	portraitImage,
	title,
	certifications,
	phoneNumber,
	office,
	email,
	industries,
	services,
}) => {
	return (
		<PageWrap>
			<Wrap>
				<div className="container" style={{ padding: '0 2rem' }}>
					<div className="columns">
						<div className="column is-4">
							<Portrait>
								<img src={portraitImage} alt="portrait" />
							</Portrait>
							<DetailList>
								<table>
									<tbody>
										<tr>
											<td>
												<img
													src="/img/SpergelCorporate_Email.svg"
													alt="email"
												/>
											</td>
											<td>{email}</td>
										</tr>
										<tr>
											<td>
												<img
													src="/img/SpergelCorporate_Phone.svg"
													alt="phone"
												/>
											</td>
											<td>{phoneNumber}</td>
										</tr>
										{vcard && (
											<tr>
												<td>
													<img
														src="/img/SpergelCorporate_vCard.svg"
														alt="vcard"
													/>
												</td>
												<td>
													<a href={vcard}>vCard</a>
												</td>
											</tr>
										)}
										{office && (
											<tr>
												<td>
													<img
														src="/img/SpergelCorporate_Location.svg"
														alt="location"
													/>
												</td>
												<td>{office}</td>
											</tr>
										)}
									</tbody>
								</table>
							</DetailList>
						</div>
						<div className="column is-8">
							<Content>
								<h2>{name}</h2>
								<h4>{certifications}</h4>
								<h3>{title}</h3>
								<div
									dangerouslySetInnerHTML={{
										__html: mdToHtml(bio),
									}}
								/>
							</Content>
						</div>
					</div>
					{industries && industries.length > 0 ? (
						<>
							<h2>Industry Specialization:</h2>
							<TagWrap>
								{industries.map((industry, i) => (
									<a href={`/industries/${slugize(industry.industry)}`} key={i}>
										<Button>{industry.industry}</Button>
									</a>
								))}
							</TagWrap>
						</>
					) : null}
					{services && services.length > 0 ? (
						<>
							<h2>Areas of Expertise:</h2>
							<TagWrap>
								{services.map((service, i) => (
									<a href={`/services/${slugize(service.service)}`} key={i}>
										<Button>{service.service}</Button>
									</a>
								))}
							</TagWrap>
						</>
					) : null}
				</div>
			</Wrap>
			<BlogFeatures />
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const TeamDetail = ({ data }) => {
	const {
		vcard,
		bio,
		name,
		portrait_image,
		title,
		certifications,
		phone_number,
		office,
		email,
		industries,
		services,
	} = data.teamJson;

	return (
		<Layout>
			<Helmet>
				<title>{name} | Spergel Corporate</title>
			</Helmet>
			<TeamDetailTemplate
				vcard={vcard}
				bio={bio}
				name={name}
				portraitImage={portrait_image}
				title={title}
				certifications={certifications}
				phoneNumber={phone_number}
				office={office}
				email={email}
				industries={industries}
				services={services}
			/>
		</Layout>
	);
};

export default TeamDetail;

export const teamDetailQuery = graphql`
	query Team($id: String!) {
		teamJson(id: { eq: $id }) {
			fields {
				slug
			}
			vcard
			bio
			name
			portrait_image
			title
			certifications
			phone_number
			office
			email
			industries {
				industry
			}
			services {
				service
			}
		}
	}
`;

const slugize = (input) => {
	const lower = input.toLowerCase();
	const withoutPeriod = lower.replace(/\./g, '');

	return withoutPeriod.replace(/ /g, '-').replace(/---/g, '-');
};

const Wrap = styled.div`
	padding: 4rem 0;
`;

const TagWrap = styled.div`
	button {
		margin-right: 1rem;
		margin-bottom: 1rem;
		font-size: 0.85rem;
		font-weight: 400;
	}
	width: 100%;
`;

const Portrait = styled.div`
	img {
		width: 100%;
	}
	margin-bottom: 1rem;
`;

const DetailList = styled.div`
	td {
		img {
			width: 2rem;
			height: 2rem;
			display: inline-block;
			margin: 0;
			margin-right: 1rem;
		}
		span {
			display: inline-block;
			line-height: 2rem;
			margin-top: -1rem;
		}
		a {
			text-decoration: none;
			color: inherit;
		}
	}
`;

const Content = styled.div`
	padding-left: 0;
	@media only screen and (min-width: 768px) {
		padding-left: 2rem;
	}
	h2 {
		font-size: 2rem;
		font-weight: 400;
		margin: 0;
	}
	h4 {
		font-size: 1rem;
		font-weight: 300;
		margin: 0;
	}
	h3 {
		font-size: 1rem;
		font-weight: 700;
		margin: 0;
		text-transform: uppercase;
		margin-bottom: 4rem;
	}
	a {
		color: inherit;
	}
`;
